<template>
  <div>
    <loader v-if="isLoading" message="Loading ...." />
    <app-form
      v-else
      type="edge"
      :value="app"
      :showModal="showModal"
      @show="showAddEndpointModal"
      @submit="editApp"
    />
  </div>
</template>

<script>
import AppForm from '@/components/apps/AppForm'
import Loader from '@/components/cards/Loader'
import { mapGetters } from 'vuex'
export default {
  name: 'EditEdge',
  components: {
    'app-form': AppForm,
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      app: 'edge/currentApp',
    }),
  },
  data() {
    return {
      showModal: this.$route.meta.showModal,
      isLoading: false,
      value: {
        name: '',
        description: '',
        forwarders: [],
      },
    }
  },
  methods: {
    showAddEndpointModal() {
      this.$router.push({ name: 'addMoreEdgeEndpoint' })
    },
    async getAppDetails() {
      this.isLoading = true
      await this.$store.dispatch('edge/getAppDetails', this.id)
      this.isLoading = false
    },
    editApp() {
      this.isSubmitting = true
      this.$store
        .dispatch('edge/editApp', { payload: this.value, id: this.id })
        .then(() => {
          this.$router.push({ name: 'edgeDetails', params: { id: this.id } })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
  watch: {
    '$route.meta'({ showModal }) {
      this.showModal = showModal
    },
    app: {
      handler() {
        if (this.app.id) {
          this.value = Object.assign({}, this.app)
          return
        }
        this.getAppDetails()
      },
      immediate: true,
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
